<template>
    <el-footer>
      <p>Copyright 2020 | HANHAISEAFOOD | All Rights Reserved</p>
    </el-footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      
    }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-footer {
  width: 100%;
  height: 100px !important;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #545c64;
}

.el-footer p{
  margin: 0 auto;
}
</style>
