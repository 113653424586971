<template>
  <el-container>
    <Header/>
    <el-main>
      <router-view></router-view>
    </el-main>
    <Footer/>
    <el-backtop></el-backtop>
  </el-container>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
name: 'App',
components: { Header, Footer},
data() {
    return {

    }
  }
}
</script>

<style>
#app {
font-family: Avenir, Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
}



.el-main {
  padding: 0;
  margin: 0;
  margin-top: 60px;
  /* color: var(--el-text-color-primary);
  width: 100%;
  margin: 60px auto 0px;
  flex: none; */
}

body > .el-container {
display: flex;
min-height: 100%;
justify-content:space-between;
flex-direction: column;
}
</style>
