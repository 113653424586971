<template>
  <div>
    <el-carousel indicator-position="outside" height="500px">
      <el-carousel-item v-for="item in carousel" :key="item">
        <img :src="item.url" style="width: 100%; height: 100%;" alt="">
        <!-- <h3>{{ item }}</h3> -->
      </el-carousel-item>
    </el-carousel>
    <div class="partner">
      <h1>Your Reliable Partner for Fresh, Sustainable, Reliable Seafood Products.</h1>
    </div>
    <div class="banner">
      <img :src="banners[0].url" style="width: 100%; height: 100%;" alt="">
    </div>
    <div class="partner">
      <h1>Safe, Fast, Convenient Transportation</h1>
    </div>
    <div class="banner">
      <img :src="banners[1].url" style="width: 100%; height: 100%;" alt="">
    </div>
    <div class="contact">
      <p>CONTACT: VICTORIA DONG</p>
      <p>TEL: 0086 18810727097 (WHATSAPP&WECHAT)</p>
      <p>EMAIL: VICTORIADY0805@163.COM</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Product',
    data() {
      return {
        carousel: [
          { url: require('../assets/carousel/carousel1.jpg') },
          { url: require('../assets/carousel/carousel2.jpg') },
          { url: require('../assets/carousel/carousel3.jpg') },
          { url: require('../assets/carousel/carousel4.jpg') },
        ],
        banners: [
          { url: require('@/assets/banner1.jpg') },
          { url: require('@/assets/banner2.jpg') },
        ]
      }
  }
}
</script>

<style>
.el-container{
  /* background: #ccc !important */
  /* background: #f5f7f9 !important; */
}

/* 走马灯 */
.el-carousel{
  /* padding-top: 60px; */
}

.el-carousel_container{
  /* padding-top: 60px; */
  height: 600px
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

  .partner{
    height: 300px;
    line-height: 300px;
    text-align: center;
    background: #f5f7f9;
    color: #000080;
  }

  h1{
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .banner{
    width: 100%;
    height: 460px;
    /* display: flex; */
    align-items: center;
    text-align: center;
    /* background: #ccc; */
  }

  img {
    object-fit: cover;
  }

  .contact{
    /* height: 200px; */
    padding: 30px 0;
    display: flex; 
    flex-flow: column; 
    align-items: center;
    /* text-align: center; */
    background: #f5f7f9;
    color: #000080;
  }

</style>
