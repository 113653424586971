<template>
  <div>
    <el-row>
      <h1 style="tile">Your Reliable Partner for Fresh, Sustainable, Reliable Seafood Products.</h1>
      <p>HANHAI INTERNATIONAL TRADE CO.,LIMITED was founded in HongKong, we are a commercial company and we specialize in a wide variety of seafood products. Our main products are:</p>
      <ul>
        <li>
          <p>Shrimps-Vannamei shrimps, Black Tiger, red & pink wild caught shrimps.</p>
        </li>
        <li>
          <p>Cephalopods-Squid, baby squid, octopus, baby octopus, ect</p>
        </li>
        <li>
          <p>Fish fillets- Salmon, COD, Saithe, Hake, Yellow fin sole, Red fish, Alaska pollock, Arrow Tooth Flounder, Mahi Mahi, Oilfish, Tuna, Panga/basa, tilapia ect.</p>
        </li>
        <li>
          <p>Products for sushi supplies - Panko, Seaweed Nori, Surimi, Soy Sauce, Gyoza, Wakame, fish roes, Chopsticks, Bamboo sushi mat, ect.</p>
        </li>
        <li>
          <p>Value added products such as: BREADED SHRIMPS/squid rings/ fish fillets&fingers/portions.</p>
        </li>
      </ul>
      <p>We have built a strong relationship with the best factories equipped with latest processing technology and all hygiene guarantees such as: HACCP, BRC, IFS, HALAL, ISO.</p>
      <p>Having supplied to various overseas customers, we understand their needs, requirements, background and habits, and always do our best to meet what customers concern. We have enough experience and understanding to bring high-quality, safe, and healthy seafood.</p>
      <p>Our business philosophy is to be a firm bridge to transport fresh, healthy and high-quality seafood to all over the world!</p>
      <p>We always welcome potential partners to establish a long-term business relationship.</p>
    </el-row>
    <div class="contact">
      <p>CONTACT: VICTORIA DONG</p>
      <p>TEL: 0086 18810727097 (WHATSAPP&WECHAT)</p>
      <p>EMAIL: VICTORIADY0805@163.COM</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
    data() {
      return {

      }
  }
}
</script>

<style scoped>
.el-row{
  width: 85vw;
  margin: 0 auto;
}

h1{
  margin: 50px auto;
  text-align: center;
  color: #000080;
}

.el-row p{
  font-size: 20px;
  color: #000080;
}

li p{
  font-size: 18px;
}

.contact{
  /* height: 200px; */
  padding: 30px 0;
  display: flex; 
  flex-flow: column; 
  align-items: center;
  /* text-align: center; */
  background: #f5f7f9;
  color: #000080;
}

.contact p{
  font-size: 16px !important;
}
</style>
