<template>
  <div>
    <el-row>
      <!-- Cephalopods -->
      <div class="divider-content">
        <p class="clear"></p>
        <ul v-for="(item, index) in categories" :key="item.category">
          <li @click="showCategory(index)" :class="{active : item.active}">{{ item.category }}</li>
        </ul>
        <p class="clear"></p>
        <ProductItem :category="category" :products="products" />
        <p class="clear"></p>
      </div>
    </el-row>
  </div>
</template>

<script>
import ProductItem from '../components/ProductItem.vue'
export default {
  name: 'Product',
  components: { ProductItem },
  data() {
    return {
      category: 'CEPHALOPODS',
      products: {},
      categories:[
        {
          category: "CEPHALOPODS",
          active: true,
        },{
          category: "FISH FILLETS",
          active: false,
        },{
          category: "BIVALVES",
          active: false,
        },{
          category: "CRUSTACEANS",
          active: false,
        },{
          category: "SEAFOOD MIX",
          active: false,
        },{
          category: "JAPANESE CONSUMPTION",
          active: false,
        },{
          category: "VALUE ADDED",
          active: false,
        },
      ],
      "CEPHALOPODS": {
        SQUID: [
          {
            title: "FROZEN SQUID RINGS IQF",
            name: "Illex Argentinus, Todarodes Pacificus, Dosidicus Gigas.",
            origin: "",
            size: "3-7CM/3-9CM/4-11CM, can be customized",
            packing: "250g bag, 500g bag, 1kg bag, 5kg/10kg bulk pack according to client requirements.",
            url: require('../assets/products/1.CEPHALOPODS/1.SQUID/1.RINGS/frozen-rings.jpg')
          },
          {
            title: "FROZEN SQUID TUBES IQF/INTERLEAVED",
            name: "Illex Argentinus, Todarodes Pacificus, Dosidicus Gigas.",
            origin: "",
            size: "U7,U5,U3,15/18CM can be customized",
            packing: "500g bag, 1kg bag, 5kg/10kg bulk pack according to client requirements.",
            url: require('../assets/products/1.CEPHALOPODS/1.SQUID/2.Tubes/FROZEN SQUID TUBES.jpg')
          },
          {
            title: "FROZEN BABY SQUID GRADE A IQF/BQF",
            name: "Loligo Japonica(Japonicus)",
            origin: "",
            size: "2-4cm, 3-5cm",
            packing: "1kg block/1kg bag.",
            url: require('../assets/products/1.CEPHALOPODS/1.SQUID/3.loligo/baby squid IQF.jpg')
          },
          {
            title: "FROZEN GIANT SQUID STRIPS",
            name: "Dosidicus Gigas.",
            origin: "",
            size: "1(+-0.5CM)*1(+-0.5CM)*8-12CM(+-2CM), can be customized",
            packing: "500g bag, 1kg bag, 5kg/10kg bulk pack according to client requirements.",
            url: require('../assets/products/1.CEPHALOPODS/1.SQUID/4.strips/strips.jpg')
          },
          {
            title: "FROZEN SQUID CUBES",
            name: "Illex Argentinus, Todarodes Pacificus, Dosidicus Gigas.",
            origin: "",
            size: "1.5-2.0cm*1.5-2.0cm,can be customized",
            packing: "5kg/10kg bulk pack according to client requirements.",
            url: require('../assets/products/1.CEPHALOPODS/1.SQUID/5.cubes/cubes.jpg')
          },
          {
            title: "FROZEN SQUID TENTACLES IQF/BQF",
            name: "Todarodes Pacificus/Illex Argentinus",
            origin: "",
            size: "10-30,30-50g",
            packing: "IQF&Block, 1 kg bag/10kg bulk packing",
            url: require('../assets/products/1.CEPHALOPODS/1.SQUID/6.tentacles/鱿鱼头.jpg')
          },
        ],
        OCTOPUS: [
          {
            title: "FROZEN WHOLE CLEAN BABY OCTOPUS FLOWER TYPE",
            origin: "China/Vietnam/Indonesia/Philippines/Malaysia",
            name: "Octopus Spp",
            size: "20-40/40-60/60-80",
            packing: "IQF&block,1 kg bag, 2kg bag, bulk pack, can be customized",
            url: require('../assets/products/1.CEPHALOPODS/2.OCTOPUS/Baby Octopus/baby Octopus.jpg')
          },
          {
            title: "FROZEN WHOLE CLEAN OCTOPUS BALL TYPE",
            origin: "Indonesia/Philippines/Malaysia",
            name: "Octopus Vulgaris",
            size: "1-2lbs,2-4lbs,4-6lbs,6-8lbs,8lbs up.",
            packing: "IWP, 30LBS carton.",
            url: require('../assets/products/1.CEPHALOPODS/2.OCTOPUS/Giant Octopus/Ball-Type.jpg')
          },
          {
            title: "FROZEN WHOLE CLEAN OCTOPUS FLOWER TYPE",
            origin: "Indonesia/Philippines/Malaysia",
            name: "Octopus Vulgaris",
            size: "1-2lbs,2-4lbs,4-6lbs,6-8lbs,8lbs up.",
            packing: "IWP, 30LBS carton.",
            url: require('../assets/products/1.CEPHALOPODS/2.OCTOPUS/Giant Octopus/flower type.jpg')
          },
        ]
      },
      "FISH FILLETS": {
        "SEA CAUGHT FISH": [
          {
            title: "FROZEN ALASKA POLLOCK FILLET IQF",
            name: "Theragra Chalcogramma",
            size: "2-4oz, 4-6oz, 6-8oz",
            packing: "5kg,7kg or 10kg bulk pack, can be customized",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/1.APO/APO.jpg')
          },
          {
            title: "Frozen Pacific/Atlantic Cod Fillet(Can be light salted)",
            name: "Gadus Macrocephalus/Gadus Morhua",
            size: "500-1000g, 1000kg up",
            packing: "10kg bulk, as client requirement",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/2.COD/COD.jpg')
          },
          {
            title: "FROZEN PACIFIC/ATLANTIC REDFISH FILLETS IQF",
            name: "Sebastes mentella / Sebastes alutus",
            size: "2-4oz,4-6oz, 6-8oz",
            packing: "10kg bulk carton.",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/3.RED FISH/RED FISH.jpg')
          },
          {
            title: "LIGHT SALTED SAITHE FILLET IQF",
            name: "Pollachius Virens",
            size: "500g+",
            packing: "5kg, 11kg bulk pack according to client requirement.",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/4.SAITHE/SAITHE.jpg')
          },
          {
            title: "FROZEN YELLOW FIN SOLE FILLETS",
            name: "Limanda Aspera",
            size: "5-7oz",
            packing: "1kg bag, 5kg or 10kg bulk pack.",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/5.YFS/YELLOW FIN SOLE.jpg')
          },
          {
            title: "FROZEN ARROW TOOTH FLOUNDER IQF",
            name: "Astheresthes Stomias",
            size: "200-400g,400-600g,600-800g",
            packing: "5kg, 7kg, 10kg bulk pack.",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/6.ATF/frozen ATF.jpg')
          },
          {
            title: "FROZEN HAKE FILLETS IQF",
            name: "Merluccius productus",
            size: "170-220g, 220-270g, 270-330g",
            packing: "1kg bag or 10kg bulk pack according to client requirement.",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/7.HAKE FILLETS/HAKE FILLETS.jpg')
          },
          {
            title: "FROZEN CHUM SALMON FISH FILLETS/PORTIONS IQF",
            name: "Oncorhynchus keta",
            size: "300-800g, 800-1000g, 1000g up/2-4oz,4-6oz, 6-8oz",
            packing: "10kg bulk pack or IVP pack according to client requirement.",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/8.SALMON/CHUM SALMON/defrost salmon 1000-1500.jpg')
          },
          {
            title: "FROZEN PINK SALMON FISH FILLETS/PORTIONS IQF",
            name: "Oncorhynchus Gorbuscha",
            size: "200-400g, 300-500g, 500-800g/2-4oz, 4-6oz",
            packing: "10kg bulk pack or IVP according to client requirements.",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/8.SALMON/PINK SALMON/pink.jpg')
          },
          {
            title: "FROZEN MAHI MAHI FILLETS IQF",
            name: "Coryphaena hippurus",
            size: "500-700g, 700g up",
            packing: "IWP, bulk pack.",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/9.MAHI MAHI/MAHI MAHI.jpg')
          },
          {
            title: "FROZEN OILFISH FILLETS SKINLESS IQF",
            name: "lepidocybium flavobrunneum",
            size: "2-4kg",
            packing: "25kg carton",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/10.oilfish/defrost product.jpg')
          },
          {
            title: "FROZEN TUNA LOINS IQF GRADE AAA(CO treated)",
            name: "Thunnus Albacares",
            size: "2-4Kg(full loins)",
            packing: "IVP with sticker, 15kg box.",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/11.TUNA/TUNA LOINS/ATUN LOINS.jpg')
          },
          {
            title: "FROZEN TUNA STEAK IQF GRADE AAA(CO treated)",
            name: "Thunnus Albacares",
            size: "170-220G",
            packing: "IVP, 5kg or 10kg box.",
            url: require('../assets/products/2.FISH FILLETS/1.SEA CAUGHT FISH/11.TUNA/TUNA STEAK/defrost steak.jpg')
          },
        ],
        "FARMED FISH": [
          {
            title: "FROZEN PANGASIUS FILLETS IQF",
            origin: "Vietnam",
            name: "Pangasius Hypophthalmus",
            size: "120-170g, 170-220g, 220-270g, can be customized",
            packing: "1kg bag, 2kg bag, bulk pack, can be customized.",
            url: require('../assets/products/2.FISH FILLETS/2.FARMED FISH/BASA/basa.jpg')
          },
          {
            title: "FROZEN PANGASIUS HGT IQF",
            origin: "Vietnam",
            name: "FROZEN PANGASIUS HGT IQF",
            size: "300-500g,500-800g,800g up, can be customized",
            packing: "bulk pack, can be customized.",
            url: require('../assets/products/2.FISH FILLETS/2.FARMED FISH/BASA/panga HGT.jpg')
          },
          {
            title: "FROZEN TILAPIA FILLET IQF( can be CO treated)",
            origin: "China",
            name: "",
            size: "2-3oz, 3-5oz, 5-7oz,7-9oz, 9oz up",
            packing: "IVP, 1kg bag, bulk pack, can be customized",
            url: require('../assets/products/2.FISH FILLETS/2.FARMED FISH/TILAPIA/TILAPIA FILLET.jpg')
          },
          {
            title: "FROZEN TILAPIA WR(can do GS, GSS, whole cleaned)",
            origin: "China",
            name: "",
            size: "100-200G, 200-300G, 300-500G, 400-600G, 500-800G, 800G +",
            packing: "1kg/bag, bulk pack, 10kgs/ctn.",
            url: require('../assets/products/2.FISH FILLETS/2.FARMED FISH/TILAPIA/tilapia GS.jpg')
          },
        ]
      },
      "BIVALVES": {
        CLAM: [
          {
            title: "Frozen boiled Clam meat IQF",
            name: "Venerupis Variegata",
            size: "200/300, 300/500,500/700,700/1000pcs",
            packing: "Retail pack or 5kg/10kg bulk pack.",
            url: require('../assets/products/3.BIVALVES/CLAM/clam meat.jpg')
          }
        ],
        MUSSEL: [
          {
            title: "Frozen Boiled Mussel Meat",
            name: "Mytilus Edulis",
            origin: 'Chile,China',
            size: "100-200,200-300,300-500pcs/kg",
            packing: "500g bag/1kg bag /10kg bulk packing",
            url: require('../assets/products/3.BIVALVES/MUSSEL/musselmeat.jpg')
          },
        ],
      },
      "CRUSTACEANS": {
        "VANNAMEI SHRIMPS COOKED": [
          {
            title: "Vannamei shrimps Cooked HLSO- IQF/Block",
            name: "",
            origin: "China",
            size: "16/20 21/25 26/30 36/40 41/50",
            packing: "500g bag, 1kg bag/box, bulk pack, can be customized",
            url: require('../assets/products/4.CRUSTACEANS/VANNAMEI SHRIMPS COOKED/CHLSO.jpg')
          },
          {
            title: "Vannamei shrimps Cooked PD Tail On- IQF",
            name: "",
            origin: "China",
            size: "16/20 21/25 26/30 36/40 41/50 51/60 61/70 71/90",
            packing: "500g bag, 1kg bag, bulk pack, can be customized",
            url: require('../assets/products/4.CRUSTACEANS/VANNAMEI SHRIMPS COOKED/PDTO COOKED.jpg')
          },
          {
            title: "Vannamei shrimps Cooked PD Tail off- IQF",
            name: "",
            origin: "China",
            size: "16/20 21/25 26/30 36/40 41/50 61/70 71/90 90-120 100/200",
            packing: "200g bag, 500g bag, 1kg bag, bulk pack, can be customized",
            url: require('../assets/products/4.CRUSTACEANS/VANNAMEI SHRIMPS COOKED/PD COOKED.jpg')
          },
          {
            title: "Vannamei shrimps CPUD-Cooked Peeled Un-Deveined- IQF",
            name: "",
            origin: "China",
            size: "61/70,71/90, 90-120,100/150, 150/200,200/300",
            packing: "200g bag, 500g bag, 1kg bag, bulk pack, can be customized",
            url: require('../assets/products/4.CRUSTACEANS/VANNAMEI SHRIMPS COOKED/PUD 100-200 cooked.jpg')
          },
        ],
        "VANNAMEI SHRIMPS RAW": [
          {
            title: "Vannamei shrimps HOSO: SEMI IQF, BRINE IQF, BLOCK.",
            origin: "Ecuador, India",
            name: "",
            size: "20/30 30/40 40/50 50/60 60/70 70/80 80/100 100/120",
            packing: "",
            url: require('../assets/products/4.CRUSTACEANS/VANNAMEI SHRIMPS RAW/HLSO.jpg')
          },
          {
            title: "Vannamei shrimps HLSO RAW IQF",
            origin: "China, Ecuador, India",
            name: "",
            size: "16/20 21/25 26/30 36/40 41/50",
            packing: "500g bag, 1kg bag,bulk pack, can be customized",
            url: require('../assets/products/4.CRUSTACEANS/VANNAMEI SHRIMPS RAW/HLSO RAW.jpg')
          },
          {
            title: "Vannamei shrimps PDTO RAW IQF",
            origin: "Ecuador,China,Vietnam.",
            name: "",
            size: "16/20 21/25 26/30 36/40 41/50",
            packing: "500g bag, 1kg bag, bulk pack, can be customized",
            url: require('../assets/products/4.CRUSTACEANS/VANNAMEI SHRIMPS RAW/RPDTO.jpg')
          },
          {
            title: "Vannamei shrimps PD RAW IQF",
            origin: "China",
            name: "",
            size: "16/20 21/25 26/30 36/40 41/50 61/70 71/90 90-120 100/200",
            packing: "500g bag, 1kg bag, bulk pack, can be customized",
            url: require('../assets/products/4.CRUSTACEANS/VANNAMEI SHRIMPS RAW/PD RAW.jpg')
          },
          {
            title: "Vannamei shrimps PUD RAW IQF",
            origin: "China",
            name: "",
            size: "61/70,71/90, 90/120,100/150, 150/200,200/300,300/500",
            packing: "500g bag, 1kg bag, bulk pack, can be customized",
            url: require('../assets/products/4.CRUSTACEANS/VANNAMEI SHRIMPS RAW/RPUD RAW.jpg')
          },
        ]
      },
      "SEAFOOD MIX": {
        "SEAFOOD MIX": [
          {
            title: "FROZEN SEAFOOD MIX IQF",
            name: "",
            size: "",
            packing: "500g, 1kg printed bag, 10kg box",
            ingredients: "Mussels, Squid rings, Octopus cuts, clams, squid tentacles, squis strips, surimi sticks, PUD shrimps, monkfish cubes. (Ingredients can be adjusted according to the market)",
            url: require('../assets/products/5.SEAFOOD MIX/SEAFOOD MIX.jpg')
          },
        ],
      },
      "JAPANESE CONSUMPTION": {
        "rosated seaweed+panko": [
          {
            title: "Yaki Sushi Nori/ROASTED SEAWEED GRADE A/B/C/D",
            name: "Porphyra Yezoensis",
            size: "19cm×20.5cm (+/-0.5cm), 2.7- 3.3g/piece",
            packing: "10sheets/bag, 50 sheets/bag, 100sheets/bag",
            url: require('../assets/products/6.JAPANESE CONSUMPTION/1.rosated seaweed+panko/NORI.jpg')
          },
          {
            title: "Japanese Panko Bread Crumbs(white/yellow/orange)",
            name: "",
            size: "2-4mm, 4-6mm",
            packing: "1kg printed bag",
            url: require('../assets/products/6.JAPANESE CONSUMPTION/1.rosated seaweed+panko/panko white color.jpg')
          },
        ],
        "surimi": [
          {
            title: "FROZEN SURIMI STICKS IQF",
            name: "Nemipterus Virgatus",
            size: "7-18cm length, straight cut",
            packing: "250g,500g,1kg VP printed bag",
            url: require('../assets/products/6.JAPANESE CONSUMPTION/2.surimi/sticks.jpg')
          },
          {
            title: "FROZEN SURIMI BITES/CHUNKS IQF",
            name: "Nemipterus Virgatus",
            size: "2cm(+-0.2cm)length straight cut, diameter 2cm(+-0.2cm)",
            packing: "1kg bag /10kg bulk according to client requirement. ",
            url: require('../assets/products/6.JAPANESE CONSUMPTION/2.surimi/chunks.jpg')
          },
        ],
        "wakame+fish roe": [
          {
            title: "Frozen Seasoned Seaweed Salad Chuka Wakame",
            name: "",
            style: "Ready-to eat, frozen instant food",
            size: "",
            packing: "1kg bag, 10kg carton.",
            url: require('../assets/products/6.JAPANESE CONSUMPTION/3. wakame+fish roe/wakame.jpg')
          },
          {
            title: "Fish Roe-TOBIKO/MASSAGO",
            name: "",
            color: "Orange, green, yellow red and black",
            size: "",
            packing: "500g*20boxes/ctn 2kg*4boxes/bundle 150g*60boxes/ctn",
            url: require('../assets/products/6.JAPANESE CONSUMPTION/3. wakame+fish roe/fish roe.jpg')
          },
        ],
        "Gyoza": [
          {
            title: "Frozen COOKED/RAW dumpling Gyoza",
            name: "",
            filling: "Salmon,shrimps, vegetables, chicken",
            size: "",
            packing: "20g*12pcs/bag 20g*20pcs/bag 20g*30pcs/bag",
            url: require('../assets/products/6.JAPANESE CONSUMPTION/4. Gyoza/Gyoza.jpg')
          },
        ],
        "soy sauce+teriyaki": [
          {
            title: "Superior Light/Dark Soy Sauce",
            name: "",
            filling: "",
            size: "",
            packing: "Can be in class bottle, jar and drum according to client requirement. 150ml,200ml,300ml,500ml,620ml,623ml,630ml,640ml,750ml,850ml,1000ml,5lbs(2.25kgs),8L(10kg),15kgs,25kgs.",
            url: require('../assets/products/6.JAPANESE CONSUMPTION/5.soy sauce+teriyaki/酱油.jpg')
          },
          {
            title: "Teriyaki Sauce",
            name: "",
            filling: "",
            size: "",
            packing: "150ml/250 glass bottle, 300ml/500ml/1L/1.8L PET bottle",
            url: require('../assets/products/6.JAPANESE CONSUMPTION/5.soy sauce+teriyaki/Teriyaki.jpg')
          },
        ],
      },
      "VALUE ADDED": {
        "BREADED FISH": [
          {
            title: "BREADED ALASKA POLLOCK/CHUM SALMON FISH FINGER",
            name: "",
            origin: "",
            size: "",
            packing: "250g/Box, 500g/Box,1Kg/Box",
            url: require('../assets/products/7.VALUE ADDED/BREADED FISH/BREADED APO FILLETS-FISH FINGER/APO finger.jpg')
          },
          {
            title: "BREADED ALASKA POLLOCK FILLET",
            name: "",
            origin: "",
            size: "",
            packing: "1Kg/bag",
            url: require('../assets/products/7.VALUE ADDED/BREADED FISH/BREADED APO FILLETS-FISH FINGER/APO FILLET.jpg')
          },
          {
            title: "Breaded Pangasius Fillet ",
            name: "",
            origin: "",
            size: "",
            packing: "1kg/bag",
            url: require('../assets/products/7.VALUE ADDED/BREADED FISH/BREADED PANGASIUS/breaded panga fillets.jpg')
          },
          {
            title: "Breaded Pangasius fish finger",
            name: "",
            origin: "",
            size: "",
            packing: "25g/pc x 8pcs/tray",
            url: require('../assets/products/7.VALUE ADDED/BREADED FISH/BREADED PANGASIUS/breaded panga fish finger.jpg')
          },
        ],
        "BREADED SHRIMPS": [
          {
            title: "Breaded PD Shrimp (Cooked or Raw)",
            origin: "",
            name: "",
            size: "",
            packing: "20g/pc x 12pcs/box",
            url: require('../assets/products/7.VALUE ADDED/BREADED SHRIMPS/breaded PD/breaded PD.jpg')
          },
          {
            title: "Breaded Butterfly Shrimp(can bread with coconut)",
            origin: "",
            name: "",
            size: "",
            packing: "20g/pc x 10pcs/tray",
            url: require('../assets/products/7.VALUE ADDED/BREADED SHRIMPS/butterfly shrimp/breaded butterfly shrimps.jpg')
          },
          {
            title: "Filo Shrimp",
            origin: "",
            name: "",
            size: "",
            packing: "20g/pc x 12pcs/pe/box",
            url: require('../assets/products/7.VALUE ADDED/BREADED SHRIMPS/FILO/FILO.jpg')
          },
          {
            title: "Breaded Torpedo Shrimp",
            origin: "",
            name: "",
            size: "",
            packing: "Packing: 30g/pc x 10pcs/tray",
            url: require('../assets/products/7.VALUE ADDED/BREADED SHRIMPS/torpedo/Torpedo shrimp.jpg')
          },
        ],
        "BREADED SQUID RINGS": [
          {
            title: "FROZEN BREADED SQUID RINGS RAW",
            origin: "",
            name: "",
            specification: "50% illex/todarodes/gigas rings+ 50% breaded panko",
            size: "",
            packing: "",
            url: require('../assets/products/7.VALUE ADDED/BREADED SQUID RINGS/breaded squid rings.jpg')
          },
        ]
      },
    }
  },
  mounted(){
    this.products = this["CEPHALOPODS"]
  },
  methods:{
    showCategory(index){
      this.categories.forEach(item => {
        item.active = false
      })
      this.categories[index].active = true
      this.category = this.categories[index].category
      this.products = this[this.categories[index].category]
      console.log(this.category)
      console.log(this[this.categories[index].category])
    }
  }
}
</script>

<style scoped>
.el-row{
  width: 95vw;
  margin: 0 auto;
}

/* .divider-content{
  display: flex;
  align-items: stretch; 
} */

/* .el-card img{
  height: 300px;
} */

ul{
  overflow: visible;
}

ul li{
  text-align: center;
  background: rgb(41, 129, 255);
  color: white;
  margin-left: 5px;
  margin-top: 5px;
  width: 250px;
  height: 50px;
  line-height: 50px;
  float: left;
  list-style: none;
  cursor: pointer;
}

ul li.active{
  color: black;
  background: rgb(36, 184, 242);
}

.clear{
  height: 10px;
  clear: both;
  /* background: #ccc; */
}

</style>
