<template>
  <div>
    <el-header>
      <el-menu router :default-active="$route.path" class="el-menu-demo" mode="horizontal"  background-color="#545c64" text-color="#fff" active-text-color="#24b8f2" >
          <!-- <div id="logo"><img src="../assets/logo.png"></div> -->
          <div id="logo"><img src="../assets/logo.png"></div>
          <el-menu-item index="/">HOME</el-menu-item>
          <el-menu-item index="/product">PRODUCT</el-menu-item>
          <!-- <el-menu-item index="/link">CONTACT</el-menu-item> -->
          <el-menu-item index="/about">ABOUT</el-menu-item>
      </el-menu>
    </el-header>
  </div>
  
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  },
  data() {
    return {
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-header {
    /* background-color: #b3c0d1; */
    color: var(--el-text-color-primary);
    text-align: center;
    line-height: 60px;
    z-index: 999;
    background-color: rgb(41, 129, 255);
    width: 100%;
    position: fixed;
}

.el-header ul{
  background: transparent !important;
}

.el-header ul li{
  background: transparent !important;
}

#logo {
    /* display: inline-block; */
    margin-right: 20px;
    float: left;
}

#logo img {
    vertical-align: middle;
    height: 36px;
}

.el-header ul {
    /* width: 1330px; */
    margin: 0 auto;
}

</style>
