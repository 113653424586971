import VueRouter, { RouterLink } from 'vue-router'
//引入组件
import Home from '../pages/Home'
import Product from '../pages/Product'
import About from '../pages/About'

const router = new VueRouter({
	routes:[
		{
			path:'/',
			component: Home,
			meta: {
				title: "HANHAI - Your Reliable Seafood Partner"
			}
		},
		{
			path:'/product',
			component: Product
		},
		{
			path:'/about',
			component: About
		}
	],
	mode: "history"
})

router.beforeEach((to, from, next) => {
	if(to.meta.title){
		document.title = "HANHAI - Your Reliable Seafood Partner"
	}
	next()
})

export default router