<template>
    <div>
      <p class="clear"></p>
      <el-divider class="divider-content-header" content-position="center">{{ category }}</el-divider>
      <div v-for="(list, key) in products" :key="key">
        <p class="sub-title">{{ key }}</p>
        <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="4" v-for="item in list" :key="item.title" :offset="0">
          <el-card :body-style="{ padding: '10px' }">
            <div class="img-container">
              <img :src="item.url" class="image">
            </div>
            <div class="product-desc">
              <p v-if="item.title">{{ item.title }}</p>
              <p v-if="item.name">Scientific name: {{ item.name }}</p>
              <p v-if="item.origin">Origin: {{ item.origin }}</p>
              <p v-if="item.style">Style: {{ item.style }}</p>
              <p v-if="item.ingredients">Ingredients: {{ item.ingredients }}</p>
              <p v-if="item.color">Color: {{ item.color }}</p>
              <p v-if="item.filling">Filling: {{ item.filling }}</p>
              <p v-if="item.size">Size: {{ item.size }}</p>
              <p v-if="item.packing">Packing: {{ item.packing }}</p>
            </div>
          </el-card>
        </el-col>
        <p class="clear"></p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ProductItem',
  props: {
    category: {
      type: String,
    },
    products: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-card{
  min-height: 600px;
  height: 600px;
  /* display: grid;
  align-items: start; */
}

.product-desc{
  /* height: 220px; */
  line-height: 20px;
}

.sub-title{
  /* margin-top: 20px; */
  /* background: #ccc; */
}

.clear{
  height: 10px;
  clear: both;
  /* background: #ccc; */
}

.el-divider__text{
  /* background: #f5f7f9; */
}

img {
  width: 100%;
  height: auto;
}

.img-container {
  width: 100%; 
  padding-top: 100%; /* 宽度的100% */
  position: relative;
}

.img-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
